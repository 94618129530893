import React from 'react';
import Layout from '../components/dfa-theme/layout';
import ContentBlock from '../components/content-block';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Awards from '../components/awards/';
import Quote from '../components/quote';
import { CONSTANTS } from '../utils/constants';
import BadgeComponent from '../components/badge-component';
import gameRoom from '../images/4.0_Careers_01.webp';
import office from '../images/4.0_Careers_02.webp';
import broadway from '../images/4.0_Careers_03.webp';


import '../scss/pages/careers.scss';

const Careers = () => {
	const metaTags = {
		name: 'Careers',
		description: 'Deerfield Careers',
		keywords: 'Deerfield, Careers',
	};

	return (
		<Layout meta={metaTags}>
			<div className='careers-hero-container'>
				<ContentBlock
					outerBgColor={CONSTANTS.BROWN}
					className='solutions addPhotoSpace block'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='careers-content title-block'>
								<div className='careers-copy'>
									<p className='header-orange'>careers</p>
									<h1>Find your calling at Deerfield</h1>
									<p className='header-copy'>
										Combining an entrepreneurial spirit with the hunger of a
										start-up, resources of a conglomerate, and an endless
										appreciation for our employees, we're ready to foster
										long-lasting and fulfilling careers.
									</p>
								</div>
								<a
									name='firstLink'
									className='dfa-button-orange'
									href='https://www.linkedin.com/company/deerfield-agency/jobs/'
									target='_blank'
									rel='noopener noreferrer'
									aria-label='Find your role at  Deerfield.'
								>
									find your role{' '}
								</a>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
			<ContentBlock
				outerBgColor={CONSTANTS.WHITE}
				className='block careers-pics-container'
			>
				<Grid>
					<Row className='careers-pics'>
						<Col xs={12} md={12} className='careers-content'>
							<img src={gameRoom} alt='' className='career-pic' />

							<img src={office} alt='' className='career-pic' />

							<img src={broadway} alt='' className='career-pic' />
						</Col>
					</Row>
				</Grid>
			</ContentBlock>

			<div className='careers'>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8}>
								<p className='dark-copy text-center intro'>
									Whether near or far, employment at Deerfield provides access
									to learning and development programs, generous salaries and
									bonuses, advancement opportunities, and other benefits to
									honor the hard work of our personnel.
								</p>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>

				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={0} md={1}></Col>
							<Col xs={12} md={10} className='icons'>
								<div className='icon-holder'>
									<div className='icon-container'>
										<div className='icon icon-medical'></div>
										<div className='icon-text'>
											{' '}
											Medical, vision, and dental benefits
										</div>
									</div>
									<div className='icon-container'>
										<div className='icon icon-pto'></div>
										<div className='icon-text'> Self-managed PTO</div>
									</div>
									<div className='icon-container'>
										<div className='icon icon-401k'></div>
										<div className='icon-text'> 401(k) savings plan</div>
									</div>
								</div>
								<div className='icon-holder'>
									<div className='icon-container'>
										<div className='icon icon-hsa'></div>
										<div className='icon-text'> Health savings account</div>
									</div>
									<div className='icon-container'>
										<div className='icon icon-lifeIns'></div>
										<div className='icon-text'> Life insurance</div>
									</div>
									<div className='icon-container'>
										<div className='icon icon-volunteer'></div>
										<div className='icon-text'> Voluntary benefits</div>
									</div>
								</div>
							</Col>
							<Col xs={0} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>

				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<h2 className='blue text-center'>
									Recognized. Certified.
									<br /> Incredibly honored.
								</h2>
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Awards className='center careers-awards' />
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>

				<ContentBlock
					outerBgColor={CONSTANTS.BLUE}
					className='block block-blue'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='callout'>
								<h2>Looking for your dream job? </h2>
								<p>
									We're hiring. If you are an egoless leader, efficient
									problem-solver, and empathetic human, we want to work with
									you.
								</p>
								<a
									name='firstLink'
									className='dfa-button-orange'
									href='https://www.linkedin.com/company/deerfield-agency/jobs/'
									target='_blank'
									rel='noopener noreferrer'
									aria-label='See jobs at Deerfield.'
								>
									apply today{' '}
								</a>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>

				<ContentBlock
					outerBgColor={CONSTANTS.WHITE}
					className='office-block block'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8}>
								<h2 className='dark text-center'>
									Not just another day in the office
								</h2>
								<p className='dark-copy text-center intro'>
									Deerfield isn't limited to a single space. Our employees carry
									their Deerfield pride with them &ndash; no matter where they
									are in the world.{' '}
								</p>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>

				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10} className='map-holder'>
								<div className='map-container'>
									<h3 className='blue skinny'>Conshohocken, PA</h3>
									<div
										dangerouslySetInnerHTML={{
											__html: `<div class="mapouter"><div class="gmap_canvas"><iframe title="google map Conshohocken" width="100%" height="300" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3052.9254933962657!2d-75.29250900081655!3d40.07707401374315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6bf3d0150e05d%3A0x3b785f249adfa232!2s555%20E%20North%20Ln%2C%20Conshohocken%2C%20PA%2019428!5e0!3m2!1sen!2sus!4v1681243133640!5m2!1sen!2sus" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div><style>.mapouter{text-align:right;height:300px;width:100%;}.gmap_canvas {overflow:hidden;background:none!important;height:450px;width:100%;}</style></div>`,
										}}
									/>
									<p className='dark-copy map mobile-spacing'>
										Meet us at our home office. Take advantage of the space to
										work independently or collaboratively. Then, relax in our
										private outdoor space with patio seating and cornhole, or
										enjoy the on-site fitness center and game room, a rotating
										schedule of food trucks, happy hours, access to public
										transportation, and so much more.{' '}
									</p>
								</div>

							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>

				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Quote
									msg={`Deerfield provided me with the platform to prove what I can do in a leadership role. The entrepreneurial environment empowers me to feel confident in leading a team of seasoned professionals to reach our client’s goal.”`}
									from={`Deerfield employee`}
								/>
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>

				<ContentBlock
					outerBgColor={CONSTANTS.BLUE}
					className='block block-blue'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='callout'>
								<h2>Looking for your dream job? </h2>
								<p>
									We’re hiring. If you are an egoless leader, efficient
									problem-solver, and empathetic human, we want to work with
									you.
								</p>
								<a
									className='dfa-button-orange'
									href='https://www.linkedin.com/company/deerfield-agency/jobs/'
									target='_blank'
									rel='noopener noreferrer'
									aria-label='See jobs at Deerfield.'
								>
									apply today{' '}
								</a>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
			<BadgeComponent isVisible={true} />
		</Layout>
	);
};

export default Careers;
